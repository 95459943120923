/// <reference types="facebook-pixel" />

declare global {
  interface Window {
    lintrk: (eventType: string, conversionData: Record<string, number>) => void;
  }
}

import type { Payment } from "@/dto/payment/payment";
import type { Job } from "@/dto/job/job";

export class TrackingEvent {
  static readonly EMPLOYER_SIGNUP_LINK_CLICKED = new TrackingEvent("employer_signup_link_clicked");
  static readonly APPSTORE_LINK_CLICKED = new TrackingEvent("appstore_link_clicked");
  static readonly CONTACT_FORM_SUBMITTED = new TrackingEvent("contact_form_submitted");
  static readonly EMPLOYER_MEMBER_CREATED = new TrackingEvent("employer_member_created");
  static readonly EMPLOYER_OWNER_CREATED = new TrackingEvent("employer_owner_created");
  static readonly JOB_CREATED = new TrackingEvent("job_created");
  static readonly JOB_REPOSTED = new TrackingEvent("job_reposted");
  static readonly PURCHASE = new TrackingEvent("purchase");

  private constructor(public readonly name: string) {}
}

export class TrackingService {
  private readonly LINKEDIN_CONVERSION_IDS = {
    employerOwnerCreated: 19402818,
    purchase: 19402826,
  };

  private clientPosthog: any = null;
  private posthogInitialized = false;
  private posthogQueue: Array<{ event: string; data?: Record<string, any> }> = [];

  constructor(
    private readonly gtag: any,
    private readonly isProduction: boolean
  ) {}

  private async initPostHog() {
    if (this.posthogInitialized) return;
    
    const { posthog } = usePostHog();
    this.clientPosthog = posthog;
    this.posthogInitialized = true;

    while (this.posthogQueue.length > 0) {
      const event = this.posthogQueue.shift();
      if (event) {
        this.clientPosthog?.capture(event.event, event.data);
      }
    }
  }

  private async sendPosthogEvent({
    event,
    data,
  }: {
    event: string;
    data?: Record<string, any>;
  }) {
    if (!this.posthogInitialized) {
      this.posthogQueue.push({ event, data });
      await this.initPostHog();
      return;
    }

    this.clientPosthog?.capture(event, data);
  }

  private sendGoogleAnalyticsEvent({
    eventType,
    event,
    data,
  }: {
    eventType: string;
    event: string;
    data?: Record<string, any>;
  }) {
    if (this.gtag) this.gtag(eventType, event, data);
  }

  private sendFacebookPixelEvent({
    eventType,
    event,
    data,
  }: {
    eventType: string;
    event: string;
    data?:
      | facebook.Pixel.ViewContentParameters
      | facebook.Pixel.SearchParameters
      | facebook.Pixel.AddToCartParameters
      | facebook.Pixel.AddToWishlistParameters
      | facebook.Pixel.InitiateCheckoutParameters
      | facebook.Pixel.AddPaymentInfoParameters
      | facebook.Pixel.PurchaseParameters
      | facebook.Pixel.LeadParameters
      | facebook.Pixel.CompleteRegistrationParameters
      | facebook.Pixel.CustomParameters;
  }) {
    if (!fbq || !this.isProduction) return;
    if (data) return fbq(eventType, event, data);
    fbq(eventType, event);
  }

  private sendLinkedInEvent({
    eventType,
    conversionData,
  }: {
    eventType: string;
    conversionData: Record<string, number>;
  }) {
    if (window.lintrk && this.isProduction) window.lintrk(eventType, conversionData);
  }

  track(event: TrackingEvent, data?: any) {
    switch (event) {
      case TrackingEvent.PURCHASE:
        this.trackPurchase(data);
        break;
      case TrackingEvent.JOB_CREATED:
      case TrackingEvent.JOB_REPOSTED:
        this.trackJob(event, data);
        break;
      case TrackingEvent.EMPLOYER_OWNER_CREATED:
        this.trackEmployerOwner();
        break;
      default:
        this.trackSimpleEvent(event);
    }
  }

  private trackPurchase(payment: Payment) {
    this.sendGoogleAnalyticsEvent({
      eventType: "event",
      event: TrackingEvent.PURCHASE.name,
      data: {
        transaction_id: payment.reference,
        value: payment.amount,
        currency: "ZAR",
        items: [
          {
            item_id: payment.reference,
            item_category: "credits",
            price: payment.amount,
            quantity: payment.credits,
          },
        ],
      },
    });

    this.sendFacebookPixelEvent({
      eventType: "track",
      event: "Purchase",
      data: {
        value: payment.amount,
        currency: "ZAR",
        content_category: "credits",
        num_items: payment.credits,
      },
    });

    this.sendLinkedInEvent({
      eventType: "track",
      conversionData: { conversion_id: this.LINKEDIN_CONVERSION_IDS.purchase },
    });

    this.sendPosthogEvent({
      event: TrackingEvent.PURCHASE.name,
      data: {
        value: payment.amount,
        currency: "ZAR",
        content_category: "credits",
        credits: payment.credits,
        transaction_id: payment.reference,
      },
    });
  }

  private trackJob(event: TrackingEvent, job: Job) {
    this.sendGoogleAnalyticsEvent({
      eventType: "event",
      event: event.name,
    });

    this.sendFacebookPixelEvent({
      eventType: "trackCustom",
      event: event.name,
      data: {
        job_id: job.id,
        job_title: job.title,
        date_created: job.created_at,
        ...(event === TrackingEvent.JOB_REPOSTED && { date_reposted: job.updated_at }),
      },
    });

    this.sendPosthogEvent({
      event: event.name,
      data: {
        job_id: job.id,
        job_title: job.title,
        date_created: job.created_at,
        ...(event === TrackingEvent.JOB_REPOSTED && { date_reposted: job.updated_at }),
      },
    });
  }

  private trackEmployerOwner() {
    this.sendGoogleAnalyticsEvent({
      eventType: "event",
      event: TrackingEvent.EMPLOYER_OWNER_CREATED.name,
    });

    this.sendFacebookPixelEvent({
      eventType: "trackCustom",
      event: TrackingEvent.EMPLOYER_OWNER_CREATED.name,
    });

    this.sendLinkedInEvent({
      eventType: "track",
      conversionData: { conversion_id: this.LINKEDIN_CONVERSION_IDS.employerOwnerCreated },
    });

    this.sendPosthogEvent({
      event: TrackingEvent.EMPLOYER_OWNER_CREATED.name,
    });
  }

  private trackSimpleEvent(event: TrackingEvent) {
    this.sendGoogleAnalyticsEvent({
      eventType: "event",
      event: event.name,
    });

    this.sendPosthogEvent({
      event: event.name,
    });
  }
}
